import {
  useSelector,
  useDispatch
} from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import { parseTextImage } from '../ecritsComponents/parseTextImage';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import {
  setSearchTags,
  setSearchKeyword
} from '../../reduxhandler/articleredux/articleSlice';
import { Helmet } from 'react-helmet-async';

// Conteneurs stylisés
const ArticlesContainer = styled.div`
  position: absolute;
  width: 63%;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  right: 12%;
  top: 0px;
  transform: translate(-50% 50px);
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  opacity: 0.9;
  border-radius: 30px;
  padding: 20px;
  overflow: auto;

  @media (min-width: 200px) and (max-width: 800px) {
    width: 100%;
    height: 500px;
    top: 320px;
    right: 0px;
    margin: 0;
    padding: 0px;
    left: 0px;
  }
`;

const NoArticlesMessage = styled.div`
  text-align: center;
  font-size: 1.2em;
  color: ${variablesGlobals.darkGreyBack};
  margin-top: 20px;
`;

const ReadMoreButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  color: ${variablesGlobals.darkGreyBack};
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${variablesGlobals.veryDarkGreyBack};
  }
`;

const ArticleContain = styled(motion.div)`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 30px;
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.8em;
  }
  & h3 {
    text-align: center;
    margin: 0;
    width: 100%;
    padding: 3px 20px;
    box-sizing: border-box;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  user-select: none;
  & div {
    background-color: ${variablesGlobals.darkGreyBack};
    color: white;
    border-radius: 30px;
    padding: 5px 10px;
    margin: 2px;
    font-size: 0.7em;
  }
`;

const ArticleDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  flex-direction: row;
  font-size: 1em;
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.7em;
  }
  & img {
    border-radius: 15px;
    overflow: hidden;
    margin-right: 10px;
  }
`;

function ArticleComponent() {
  const dispatch = useDispatch();

  const {
    listArticle = [],
    searchTags = [],
    searchKeyword = ''
  } = useSelector(
    (state) => state.articleReducer || {}
  );

  const [filteredArticles, setFilteredArticles] =
    useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Réinitialiser les tags et mots-clés à chaque visite
    dispatch(setSearchTags([]));
    dispatch(setSearchKeyword(''));
  }, [dispatch]);

  useEffect(() => {
    // Filtrer les articles à chaque modification des tags ou mots-clés
    const filtered = listArticle.filter(
      (article) => {
        if (!article) return false;

        const matchesTags =
          searchTags.length === 0 ||
          (Array.isArray(article.tags) &&
            searchTags.every((tag) =>
              article.tags.includes(tag)
            ));

        const matchesKeyword =
          !searchKeyword ||
          (article.title &&
            article.title
              .toLowerCase()
              .includes(
                searchKeyword.toLowerCase()
              )) ||
          (article.text &&
            article.text
              .toLowerCase()
              .includes(
                searchKeyword.toLowerCase()
              )) ||
          (article.description &&
            article.description
              .toLowerCase()
              .includes(
                searchKeyword.toLowerCase()
              ));

        return matchesTags && matchesKeyword;
      }
    );

    setFilteredArticles(filtered);

    // Déterminer le message à afficher
    if (
      searchTags.length > 0 &&
      filtered.length === 0
    ) {
      setMessage(
        'Aucun article ne correspond aux tags sélectionnés.'
      );
    } else if (
      searchKeyword.trim() !== '' &&
      filtered.length === 0
    ) {
      setMessage(
        'Aucun article ne correspond à votre recherche.'
      );
    } else {
      setMessage('');
    }
  }, [listArticle, searchTags, searchKeyword]);

  const url = 'images/';

  return (
    <>
      {/* Helmet pour la SEO dynamique */}
      <Helmet>
        <title>Le carnet</title>
        <meta
          name="description"
          content={
            'Retrouvez mes activités dans le carnet'
          }
        />
        <meta
          property="og:title"
          content="Le carnet"
        />
        <meta
          property="og:description"
          content="Retrouvez ici mes activitées dans le carnet"
        />
        <meta property="og:image" content="" />
      </Helmet>
      <ArticlesContainer>
        {/* Afficher le message si nécessaire */}
        {message && (
          <NoArticlesMessage>
            {message}
          </NoArticlesMessage>
        )}

        {/* Afficher les articles */}
        {filteredArticles.map(
          (article, index) => (
            <ArticleContain
              key={`${article.id}-${index}`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                delay: index * 0.3,
                duration: 0.5
              }}
            >
              {/* Tags */}
              <TagsContainer>
                {article.tags?.map((tag, i) => (
                  <div key={`${tag}-${i}`}>
                    {tag}
                  </div>
                ))}
              </TagsContainer>
              <h3>{article.title}</h3>
              <small>
                {new Date(
                  article.date
                ).toLocaleDateString('fr-FR')}
              </small>
              {/* Description et image */}
              <ArticleDescription>
                {article.images?.[0]?.path && (
                  <img
                    src={`${url}${article.images[0].path}`}
                    alt={article.title}
                    width="150px"
                  />
                )}
                {parseTextImage(
                  article.description,
                  article.images,
                  article.notes,
                  url
                )}
              </ArticleDescription>
              {/* Bouton "Lire la suite" */}
              <ReadMoreButton
                to={`/article/${article.title
                  .toLowerCase()
                  .replace(/\s+/g, '-')}`}
              >
                Lire la suite...
              </ReadMoreButton>
            </ArticleContain>
          )
        )}
      </ArticlesContainer>
    </>
  );
}

export default ArticleComponent;
